<template>
  <!-- :key="" required to make sure it reloads when we change the users roles dynamically -->
  <!--  - Ex: subscription expired, admin.internal giving demo's -->
  <sidenav :orientation="orientation" :class="curClasses" class="d-print-none" :key="JSON.stringify($root.me.roles)">

    <div class="app-brand" v-if="orientation !== 'horizontal'">
      <router-link to="/" class="app-brand-text sidenav-text font-weight-normal mb-3">
        <div class="app-brand-logo"></div>
      </router-link>
    </div>

    <!-- Inner -->
    <div class="sidenav-inner" :class="{ 'py-1': orientation !== 'horizontal' }">
      <!-- Free trial -->
      <div class="w-100 mt-2" v-if="$root.shouldShowSubscriptionOptions && $root.client && $root.client.subscription_usage && $root.client.subscription_usage.trial_days_remaining">
        <div id="free-trial-sidenav" class="bg-success-light text-dark mb-3 mx-3 p-3">
          <div class="text-primary font-weight-bolder letter-spacing-1px">FREE TRIAL</div>
          <div v-if="$root.client.subscription_usage.trial_days_remaining > 1">{{ $root.client.subscription_usage.trial_days_remaining }} days remaining</div>
          <div v-else-if="$root.client.subscription_usage.trial_days_remaining === 1">{{ $root.client.subscription_usage.trial_days_remaining }} day remaining</div>
          <div class="text-right">
            <router-link :to="{ name: 'account', query: { item: 'createSubscription' } }" class="btn-primary btn-sm text-uppercase d-inline-block mt-2">See pricing <span class="material-icons md-14 arrow-east p-0" style="vertical-align: sub;">east</span></router-link>
          </div>
        </div>
        <div id="free-trial-small-sidenav" class="bg-success-light text-dark mb-3 mx-3" style="width: 2.375rem;">
          <div class="text-primary text-center line-height-1 font-weight-bolder letter-spacing-1px small rotate--90 py-3">
            <router-link :to="{ name: 'account', query: { item: 'createSubscription' } }">FREE TRIAL</router-link>
          </div>
        </div>
      </div>
      <!-- Free trial -->

      <sidenav-router-link icon="dashboard" to="/" :exact="true" class="pl-0">Dashboard</sidenav-router-link>
      <sidenav-divider/>

      <template v-if="this.$root.me.grants.combined.length">
        <template v-if="$root.hasRole('admin.*') || $root.hasRole('reporting')">
          <sidenav-header class="small font-weight-bolder letter-spacing-1px">INSIGHTS</sidenav-header>
          <sidenav-router-link icon="bar_chart" :to="{name: 'form-insights'}">Form engagement</sidenav-router-link>
          <sidenav-router-link icon="timeline" :to="{name: 'field-metrics'}">Field metrics</sidenav-router-link>
          <sidenav-router-link icon="videocam" :to="{name: 'session-replays'}">Session replays</sidenav-router-link>
          <sidenav-router-link icon="compare" :to="{name: 'industry-benchmarks'}" class="isNew">Industry benchmarks</sidenav-router-link>
          <sidenav-router-link icon="track_changes" :to="{name: 'score'}">Score</sidenav-router-link>
          <sidenav-divider/>
        </template>

        <template v-if="$root.hasRole('admin.*') || $root.hasRole('configuration.*') || $root.hasRole('reporting')">
          <sidenav-header class="small font-weight-bolder letter-spacing-1px">PERFORMANCE ALERTS</sidenav-header>
          <sidenav-router-link icon="query_stats" :to="{name: 'performance-alerts-checks'}" v-if="$root.hasRole('admin.*') || $root.hasRole('configuration.*')">Monitors</sidenav-router-link>
          <sidenav-router-link icon="notifications" :to="{name: 'performance-alerts-notifications'}">Notifications</sidenav-router-link>
          <!--<sidenav-router-link icon="sms" :to="{name: 'notification-channels'}">Notification channels</sidenav-router-link>-->
          <sidenav-divider/>
        </template>

        <template v-if="$root.hasRole('admin.*') || $root.hasRole('configuration.*')">
          <sidenav-header class="small font-weight-bolder letter-spacing-1px">SMART TOOLTIPS</sidenav-header>
          <sidenav-router-link icon="message" :to="{name: 'st-forms'}">Campaigns</sidenav-router-link>
          <sidenav-router-link icon="ballot" :to="{name: 'st-library'}">Library</sidenav-router-link>
          <sidenav-router-link v-if="$root.hasRole('admin.internal')" link-class="adminOnly" icon="palette" :to="{name: 'st-templates'}">Templates</sidenav-router-link>
          <sidenav-divider/>
        </template>

        <template v-if="$root.hasRole('admin.*') || $root.hasRole('configuration.*')">
          <sidenav-header class="small font-weight-bolder letter-spacing-1px">SETUP</sidenav-header>
          <sidenav-router-link icon="description" :to="{name: 'forms'}">Forms</sidenav-router-link>
          <sidenav-router-link icon="code" :to="{name: 'tags'}">Tags</sidenav-router-link>
          <sidenav-divider/>
        </template>

  <!--      <template v-if="$root.hasRole('admin.internal') || $root.hasRole('demo')">-->
  <!--        <sidenav-header class="small font-weight-bolder letter-spacing-1px">INTEGRATIONS</sidenav-header>-->
  <!--        <sidenav-router-link icon="view_comfortable" :to="{name: 'integrations-partners'}">Partners</sidenav-router-link>-->
  <!--        <sidenav-divider/>-->
  <!--      </template>-->
      </template>

      <template v-if="$root.hasRole('admin.*')">
        <sidenav-header class="small font-weight-bolder letter-spacing-1px">ADMINISTRATION</sidenav-header>
        <sidenav-router-link icon="domain" link-class="adminOnly" :to="{name: 'companies'}"
                             v-if="$root.hasRole('admin.internal')">Companies</sidenav-router-link>
        <sidenav-router-link icon="domain" :to="{name: 'companies-edit', params: { id: $root.me.organization.id } }"
                             v-if="$root.hasRole('admin.*') && $root.me.organization && $root.me.organization.id">Company</sidenav-router-link>
        <sidenav-router-link icon="storefront" :to="{name: 'websites'}">Websites</sidenav-router-link>
        <sidenav-router-link icon="people" :to="{name: 'users'}">Users</sidenav-router-link>
        <sidenav-router-link icon="settings" link-class="adminOnly" :to="{name: 'admin-tools'}"
                             v-if="$root.hasRole('admin.internal') || $root.hasRole('ai.analysis')">Tools</sidenav-router-link>
        <sidenav-divider/>
      </template>

      <sidenav-header class="small font-weight-bolder letter-spacing-1px">SUPPORT</sidenav-header>
      <div class="sidenav-item">
        <a href="https://support.exatom.io/" class="sidenav-link" target="_blank"><i class="material-icons sidenav-icon">menu_book</i><div>Knowledge base</div></a>
      </div>
      <div class="sidenav-item">
        <a href="" @click.prevent="$root.createTicket" class="sidenav-link"><i class="material-icons sidenav-icon">email</i><div>Contact support</div></a>
      </div>
    </div>

    <div id="menu-toggle" class="cursor-pointer d-lg-block d-none" @click="toggleSidenav"></div>
  </sidenav>
</template>

<script>
import { Sidenav, SidenavLink, SidenavRouterLink, SidenavMenu, SidenavHeader, SidenavBlock, SidenavDivider } from '@/vendor/libs/sidenav'

export default {
  name: 'side-navigation',
  components: {
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider
    /* eslint-enable vue/no-unused-components */
  },

  props: {
    orientation: {
      type: String,
      default: 'vertical'
    }
  },

  data () {
    return {
      toggleMenuIcon: 'keyboard_arrow_left'
    }
  },

  computed: {
    curClasses () {
      let bg = this.layoutSidenavBg

      if (this.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
        bg = bg
          .replace(' sidenav-dark', '')
          .replace(' sidenav-light', '')
          .replace('-darker', '')
          .replace('-dark', '')
      }

      return `bg-${bg} ` + (
        this.orientation !== 'horizontal'
          ? 'layout-sidenav'
          : 'layout-sidenav-horizontal container-p-x flex-grow-0'
      )
    }
  },

  methods: {
    isMenuActive (url) {
      return this.$route.path.indexOf(url) === 0
    },

    isMenuOpen (url) {
      return this.$route.path.indexOf(url) === 0 && this.orientation !== 'horizontal'
    },

    toggleSidenav () {
      this.layoutHelpers.toggleCollapsed()
      this.toggleMenuIcon = this.toggleMenuIcon === 'keyboard_arrow_left' ? 'keyboard_arrow_right' : 'keyboard_arrow_left'
    }
  }
}
</script>

<style>
.layout-collapsed:not(.layout-sidenav-hover) div #free-trial-sidenav {
  display: none;
}
#free-trial-small-sidenav {
  display: none;
}
.layout-collapsed:not(.layout-sidenav-hover) div #free-trial-small-sidenav {
  display: block;
}

.sidenav-vertical {
  overflow: visible;
}
.layout-collapsed:not(.layout-sidenav-hover) .sidenav-item a div,
.layout-collapsed:not(.layout-sidenav-hover) .sidenav-header {
  visibility: hidden;
}
.layout-collapsed:not(.layout-sidenav-hover) .sidenav-item.active {
  background: none;
}
.layout-collapsed:not(.layout-sidenav-hover) .sidenav-inner {
  width: 4.375rem;
}

#menu-toggle {
  position: absolute;
  left: 15.625rem;
  top: 120px;
  bottom: 120px;
  padding: 0 16px 0 12px;
  cursor: pointer;
  z-index: 1024;
}
.layout-collapsed:not(.layout-sidenav-hover) #menu-toggle {
  visibility: hidden;
}

#menu-toggle:after, #menu-toggle:before {
  content: "";
  width: 20px;
  height: 3px;
  position: absolute;
  background-color: var(--exatom-grey-plus1);
  bottom: 12px;
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: .2s;
  top: 50vh;
  transform: translateY(-50%) rotate(90deg);
  border-radius: 2px;
}
#menu-toggle:before {
  top: calc(50vh - 17px);
}
#menu-toggle:after {
  bottom: calc(50vh - 17px);
}
#menu-toggle:hover:before {
  transform: rotate(114deg);
}
#menu-toggle:hover:after, #menu-toggle:hover:before {
  background-color: var(--exatom-blue-min1);
}
#menu-toggle:hover:after {
  transform: rotate(66deg);
}
#menu-toggle:hover:after, #menu-toggle:hover:before {
  background-color: var(--exatom-blue-min1);
}
.layout-collapsed.layout-sidenav-hover #menu-toggle:hover:before {
  transform: rotate(66deg);
}
.layout-collapsed.layout-sidenav-hover #menu-toggle:hover:after {
  transform: rotate(114deg);
}
</style>
